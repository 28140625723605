/* roboto-condensed-regular - latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/roboto-condensed-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"),
    url("./assets/fonts/roboto-condensed-v18-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/roboto-condensed-v18-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/roboto-condensed-v18-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/roboto-condensed-v18-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/roboto-condensed-v18-latin-regular.svg#RobotoCondensed")
      format("svg"); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, "Roboto Condensed", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
  user-select: none;
}

.full-page {
  width: 100vw;
  height: 100vh;
}

/* .dropdown-menu {
  right: 0;
  left: auto;
} */

.centered {
  position: fixed;
  top: 42%;
  left: 50%;
  transform: translate(-50%, 0);
}

.sticky-top {
  font-size: 90%;
  padding: 0.3rem;
  position: fixed;
  text-align: center;
  width: 100%;
  display: block;
  background-color: rgba(128, 135, 128, 0.1);
}

.hidden {
  display: none;
}

a {
  color: #333;
}

button:focus {
  outline: 0;
}

.btn,
.btn-lg,
.modal-content {
  border-radius: 0;
}

.navbar {
  padding: 0 1rem;
}

.modal-content {
  border: none;
}

.modal-header {
  background-color: #8cd4d1;
  border-radius: 0;
  padding: 0;
}

.modal-body {
  padding: 0;
}

.no-machine {
  background-color: white;
  width: 500px;
  height: 300px;
  margin: 15% auto 0 auto;
  border: #ddd 1px solid;
  box-shadow: #ccc 2px solid;
  box-sizing: border-box;
  text-align: center;
  padding-top: 8%;
  line-height: 2.3rem;
  font-size: 1.1rem;
}

.refreshBtn:hover {
  transform: rotate(180deg);
  transition: transform 1s ease;
}

.rouster-label {
  font-size: 1.5vw;
  text-align: center;
}

.rouster-icon {
  display: flex;
  border: 0.2vw solid #444;
  transition: border-color 1.5s ease;
  border-radius: 50%;
  width: 5vw;
  height: 5vw;
  background: #fff;
  float: left;
  margin-right: 2vw;
  margin-left: 2vw;
  position: relative;
}

.rouster-icon::before {
  content: "";
  position: absolute;
  top: 2.3vw; /* (5 / 2) - 0.2 */
  left: -4.2vw;
  width: 4.2vw;
  height: 0.2vw;
  border-bottom: 0.2vw solid #444;
  background: transparent;
  transition: background-color 2s ease;
  z-index: -1;
}

.rouster-icon:first-child::before {
  display: none;
}

.rouster-icon > i {
  display: block;
  width: 4vw;
  height: 4vw;
  background-position: center;
  background-size: cover;
  margin: auto auto;
}

.rouster-icon.active {
  border-color: #333;
  border-style: solid;
}

.rouster-icon .label {
  position: absolute;
  text-align: center;
  width: 4.4vw;
  top: -1.7vw;
  color: #ccc;
}

.rouster-icon.active .label {
  color: #000;
}

.rouster-icon.active ~ .rouster-icon {
  border-style: dashed;
  border-color: #bbb;
}

.rouster-icon.active ~ .rouster-icon::before {
  border-bottom: 0.2vw dashed #bbb;
}

.sloganPrefix {
  color: #8cd4d1;
  font-size: 120%;
  font-weight: bold;
  margin: 0 10px;
}

.page-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.page-overlay div {
  color: #222222;
  background-color: #888888;
  margin-top: 40px;
}

/*
.bg-image {
  position: fixed;
  width: 100%;
  background: url("./assets/bg/19921.jpg") no-repeat center center fixed;
  background-size: cover;
  filter: blur(9px);
  transform: scale(1.1);
  height: 100%;
}*/

.invalid {
  border: 1px solid #ff0000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.row {
  margin: 0;
}

.col {
  padding: 0;
}

.card {
  border: none;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
}

.list-group {
  max-height: 300px;
  margin-bottom: 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modal-header .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
  line-height: 28px;
  /*border-bottom: 3px solid rgba(0, 0, 0, 0);*/
}

.modal-header .navbar-nav .nav-link.active {
  animation: glow 1s ease-in-out infinite alternate;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #7bc3c0, 0 0 40px #7bc3c0, 0 0 50px #7bc3c0, 0 0 60px #7bc3c0,
    0 0 70px #7bc3c0;
}

/*
.list-group a.nav-link > i {
  transition: transform 0.5s ease;
}

.list-group a.nav-link:hover > i {
  transform: scale(1.2);
}*/

.dropdown-toggle::after {
  display: none;
}

/** Icon Preloader */

/** Spinner */
.spinner {
  width: 40px;
  height: 40px;
  background-color: #333;

  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/** State */

.stateDot {
  /*
  position: absolute;
  top: 5px;
  left: 5px;*/
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  padding: 0;
  margin: auto;
  z-index: 20;
}
.stateDot.state-on {
  box-shadow: 0 0 8px rgba(131, 236, 82, 0.99);
  background-color: #3cff00;
  color: #45ff2d;
  animation: pulseShadow 2s ease-in-out infinite;
}
.stateDot.state-on:before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(113, 236, 82, 0.1);
  background: rgba(113, 236, 82, 0.14);
  border-radius: 50px;
  opacity: 0;
  transform: scale(1);
  animation: pulse 2s linear infinite;
}
.stateDot.state-pending {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 0 8px rgba(230, 255, 2, 0.99);
  background-color: #ffdfc8;
  color: #ecdc51;
}
.stateDot.state-available {
  box-shadow: 0 0 8px rgba(131, 236, 82, 0.99);
  background-color: #fff;
}

.modal-body .stateDot{
  float: right;
}

.stateDot.state-off {
  color: #0f0f1d;
  background-color: #3b4050;
}
@keyframes pulse {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  33% {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(1.4);
    opacity: 0;
  }
}

.iframe-container {
  padding-top: 0px;
  position: relative;
  width: 1920px;
  height: 1080px;
  transform: scale(0.6);
  margin-left: -100px;
  margin-top: -200px;
}
 
.iframe-container iframe {
   border: 0;
   height: 1080px;
   left: 0;
   position: absolute;
   top: 0;
   width: 1920px;
}